/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  .bg-light {
    background-color: #f8f9fa !important;
  }
} */
@font-face {
  font-family: 'Courier New';
  src: url(./fonts/cour.ttf);
}
.userStepOnePage, .userGame2Page, .userStepThreePage, .userStepTwoPage {
  font-family: 'Courier New', Courier, monospace;
}
#img-icon,
#file-icon {
  font-size: 16vw;
  opacity: 0.7;
}
#file-icon {
  font-size: 14.6vw;
  opacity: 0.7;
}
input[type="file"] {
  box-shadow: 0px 0px 10px gray;
}
input[type="email"] {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #212f3c;
  box-shadow: none !important;
  outline: 0 !important;
}
input[type="text"] {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #212f3c;
  box-shadow: none !important;
  outline: 0 !important;
}
input[type="password"] {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #212f3c;
  box-shadow: none !important;
  outline: 0 !important;
}
.adminLoginHeader h1 {
  font-size: 3vw;
}

/* ========================================= Header ========================================= */
.header {
  z-index: 1;
}
.header .logoutLink i {
  font-size: 25px;
}
.header .logoutLink a:hover {
  font-size: 30px;
}
/* ========================================= Side Navbar ========================================= */

.tab-display {
  display: block !important;
}
.tab-display .keyboard-instructions, .tab-display #hint_right, .tab-display #hint_left{
  display: none !important;
}
.tab-display .touchscreen-instructions{
  display: block !important;
}
.sideNav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 12vw; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  top: 0%; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 8vh !important;
  text-align: start;
}
.sideNav ul {
  padding: 0px;
  padding-left: 4%;
  list-style: none;
}

.sideNav ul li {
  padding-top: 10%;
}

.sideNav ul li a {
  text-decoration: none;
}
/* ================================================ App Logo ================================================ */

.appLogo {
  position: absolute;
  top: 0.5vh;
  left: 1vw;
  font-size: 2.5vw;
}

/* ================================================ Upload Forms ================================================ */

.uploadForms {
  width: 100% !important;
}
.uploadForms .row {
  margin-left: 10%;
}

/* ================================================ Image View ================================================ */

.imagesView {
  width: auto;
  margin-left: 12vw;
}
.imagesView form {
  width: 50%;
  margin: auto;
}
.imagesView img {
  width: 250px;
  height: 250px;
}

/* ================================================ Words Page ================================================ */

.wordsPage {
  width: auto;
  margin-left: 12vw;
}
.wordsPage form {
  width: 50%;
  margin: auto;
}
.singleWord {
  font-weight: 500;
}
/* ================================================ User SignUp Page ================================================ */

.userSignupPage {
  margin-top: 5%;
  width: auto;
  margin-left: 12vw;
}
.userSignupPage form {
  width: 50%;
  margin: auto;
}

/* ================================================ All Users Page ================================================ */

.allUsersPage {
  width: auto;
  margin-left: 12vw;
}
/* ================================================ Suspended Users Page ================================================ */
.suspendedUserPage {
  width: auto;
  margin-left: 12vw;
}
/* ================================================ Change Password Page ================================================ */

.passwordPage {
  width: auto;
  margin-left: 12vw;
}

.passwordPage form {
  margin-top: auto;
}

.passwordPage form input {
  width: 100%;
  margin: auto;
}
.passwordPage .passwordFiledsWrapper {
  width: 50%;
  padding: 5%;
  margin: auto;
}
/* ================================================ Categories Page ================================================ */

.categoriesPage {
  width: auto;
  margin-left: 12vw;
}

.categoriesPage .allCatsWrapper {
  margin-top: 7%;
}

.categoriesPage .allCatTable td {
  text-align: left;
}

/* ================================================ ADMIN Instruction Page ================================================ */

.instructionPage {
  width: auto;
  margin-left: 12vw;
}

/* ================================================ ADMIN Step 1 Page ================================================ */
.stepOnePage {
  width: auto;
  margin-left: 12vw;
}
.stepOnePage .iterationsBox {
  margin-top: 5% !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px grey;
}

.stepOnePage #all_words_table {
  display: none;
  height: 85vh;
  overflow-y: scroll;
}

/* ================================================ ADMIN Step 2 Page ================================================ */
.stepTwoPage {
  width: auto;
  margin-left: 12vw;
}
.stepTwoPage .iterationsBox {
  margin-top: 5% !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px grey;
}

/* ================================================ ADMIN Step 3 Page ================================================ */
.stepThreePage {
  width: auto;
  margin-left: 12vw;
}
/* ===================================== Admin Game 2 Page ========================== */

.game2CategoriesPage {
  width: auto;
  margin-left: 12vw;
}

.game2CategoriesPage .allCategories {
  visibility: hidden;
}
.game2AddLabelPage {
  width: auto;
  margin-left: 12vw;
}

.game2AllLabelsPage {
  width: auto;
  margin-left: 12vw;
}
.game2UploadPage {
  width: auto;
  margin-left: 12vw !important;
}
.game2DisplayWords {
  width: auto;
  margin-left: 12vw !important;
}
.game2DisplayImages {
  width: auto;
  margin-left: 12vw !important;
}

/* ================================================ User Step 1 Page ================================================ */

.userStepOnePage {
  box-shadow: 2px 2px 12px gray;
  width: 50vw;
  margin-top: 10vh;
  min-height: 450px;
  height: 60vh;
  background-color: rgb(248, 248, 236) !important;
}
.userStepOnePage #flashAlphabet, .userStepOnePage #flashAlphabet2 {
  margin: auto;
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  display: none;
}
.userStepOnePage #wordContainer, .userStepOnePage #nineXContainer {
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
}
.userStepOnePage #buttons {
  display: none;
}
.userStepOnePage #nextStep {
  padding-top: 10vh;
  display: none;
}

/* ================================================ User Step 2 Page ================================================ */

.userStepTwoPage {
  box-shadow: 2px 2px 12px gray;
  margin: 5rem auto 5rem auto;
  overflow: scroll;
  background-color: rgb(248, 248, 236) !important;
}

.userStepTwoPage .tableWrapper {
  margin: auto;
  display: none;
}
.userStepTwoPage .tableWrapper .quadrantTable {
  margin: auto;
  width: 90%;
  height: 110%;
}
.userStepTwoPage .tableWrapper .quadrantTable img {
  margin: auto;
  max-width: 150px;
  width: auto;
  height: auto;
}
.userStepTwoPage .tableWrapper .quadrantTable td {
  text-align: center;
  width: 50%;
  height: 250px !important;
  padding: .7rem;
}
.userStepTwoPage .tableWrapper .quadrantTable #first_quad {
  border-bottom: 2px solid #343a40;

}
.userStepTwoPage .tableWrapper .quadrantTable #second_quad {
  border-left: 2px solid #343a40;
}
.userStepTwoPage .tableWrapper .quadrantTable #third_quad {
  border-right: 2px solid #343a40;
}
.userStepTwoPage .tableWrapper .quadrantTable #fourth_quad {
  border-top: 2px solid #343a40;
}

.userStepTwoPage #step3_btn {
  display: none;
  margin-top: 1.5rem;
  margin: auto;
}

/* ================================================ User Step 3 Page ================================================ */

.userStepThreePage {
  box-shadow: 2px 2px 12px gray;
  width: 50vw;
  height: auto !important;
  margin-top: 10vh;
  background-color: rgb(248, 248, 236) !important;
}

.userStepThreePage .table2x4 {
  margin: auto;
  width: 95%;
  height: 90%;
}

.userStepThreePage .table2x4 td {
  height: 50%;
  width: 25%;
  border: 1px solid black;
  cursor: context-menu;
}
.userStepThreePage .table2x4 h4 {
  font-size: 1.5rem;
  margin:auto;
  /* padding-top: 0%;
  padding-bottom: 0%;
  font-size: 3.5vw; */
}

.userStepThreePage .flashWord {
  /* background-color: gold; */
  display: inline-flex;
}
/* 
.userStepThreePage .flashWord .imageInside {
  width: 100% !important;
} */

.userStepThreePage .flashWord .wordInside h4 {
  border: 1px solid black;
  min-width: 12vw;
  padding-top: 50%;
  height: 16vw;
}
.userStepThreePage #flash_images span {
  color: green;
  font-size: 20px;
}
/* .userStepThreePage #flash_result .resultImages {
  display: inline-flex;
} */

/* .userStepThreePage #flash_result .resultImages .wordInside h4 {
  border: 1px solid black;
  min-width: 8vw;
  padding-top: 12%;
  height: 10vh;
} */

.userStepThreePage #flash_result img {
  width: 30%;
  height: 40%;
  padding-left: 2%;
  padding-right: 2%;
}
.userStepThreePage #flash_result .incorrect {
  display: inline-flex;
}
/* .userStepThreePage #flash_result #result_img {
  width: 10vw;
  height: 10vh;
  font: 20px;
} */
#pair_img1 h4 {
  margin: auto;
  /* padding-top: 25px; */
}
#pair_img2 h4 {
  /* padding-top: 25px; */
  padding-top: 65%;
  padding-bottom: 50%;
}
#pair_img1 {
  cursor: context-menu;
}

#pair_img2 {
  cursor: context-menu;
}
#random_img0 {
  cursor: context-menu;
}
#random_img1 {
  cursor: context-menu;
}
#random_img2 {
  cursor: context-menu;
}
#random_img3 {
  cursor: context-menu;
}
#random_img4 {
  cursor: context-menu;
}

.userStepThreePage .table2x4 img {
  width: 100%;
}
.userStepThreePage .numOfTimesCount {
  font-size: 20px;
  display: none;
}
.userStepThreePage #displayResults {
  padding-top: 25% !important;
}

.userStepThreePage #game2_btn {
  display: none;
  margin-top: 25vh;
  margin-right: 5%;
}

/* ========================================== User Game 2 ========================================== */

.userGame2Page {
  box-shadow: 2px 2px 12px gray;
  width: 50vw !important;
  margin-top: 10vh;
  background-color: rgb(248, 248, 236) !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  position: relative;
  min-height: 70vh;
  overflow: hidden;
}

.userGame2Page .instructions1 p {
  font-size: 15px;
}
.userGame2Page #_cross {
  display: none;
}
.userGame2Page #_flash_image {
  width: 10vw;
}
.categories{
  min-height: 100px;
}
.userGame2Page #_mobile_buttons #_btn_e {
  background-color: rgb(192, 247, 192);
  border: 0.1px solid green;
  position: absolute;
  top:30%;
  height:  75vh !important;
}
.userGame2Page #_mobile_buttons #_btn_i {
  background-color: rgb(192, 247, 192);
  border: 0.1px solid green;
  position: absolute;
  right: 0;
  top:30%;
  height:  75vh !important;
}
#hint_left,
#hint_right {
  display: none;
}
/* ========================================== Media Queries ========================================== */
@media only screen and (max-width: 930px) and (max-height: 430px) {

  .userStepTwoPage .tableWrapper .quadrantTable td {
    height: 180px !important;
  }
  .userStepTwoPage .tableWrapper .quadrantTable td img {
    max-width: 110px;
  }
  .userStepOnePage {
    min-height: 80vh;
  }
  .userStepTwoPage{
    height: 500px;
  }
  .userGame2Page #_mobile_buttons #_btn_e, .userGame2Page #_mobile_buttons #_btn_i {
    top: 30vh;
  }
}
@media only screen and (max-width: 280px) {
  .userStepOnePage #buttons button {
    display: block;
    text-align: center;
  }
  .userGame2Page #_mobile_buttons {
    display: block;
  }
  .userGame2Page #_btn_start {
    display: block;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* #wrapper_of_flash_imgs #_flash_images{
    min-height: 90px !important;    
  } */
  #_flash_word{
    margin-top: 40% !important;
  }
  .userStepTwoPage {
    width: 100% !important;
    height: 93.1vh !important;
    margin: 0%;
  }
  .userStepThreePage .flashWord {
    /* background-color: gold; */
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }

  .keyboard-instructions {
    display: none;
  }
  .touchscreen-instructions {
    display: block;
  }

  .userStepThreePage .flashWord .wordInside h4 {
    /* width: 10vw; */
    min-width: 21vw;
    padding-top: 50%;
    height: 100%;
    font-size: 16px;
  }
  .userStepThreePage .table2x4 td {
    min-width: 20vw;
    border: 1px solid black;
    cursor: context-menu;
  }
  .userStepThreePage .table2x4 h4 {
    font-size: 4vw;
  }
  body {
    scroll-margin-top: 0%;
    overflow: hidden;
  }
  .stepOneInstructions {
    font-size: 14px;
  }
  .userStepTwoPage .stepTwoInstructions {
    font-size: 14px;
  }
  .stepThreeInstructions {
    font-size: 14px;
  }

  #startButton h4, #startFreshButton h4 {
    font-size: 18px;
  }
 
  #startButton3 h4 {
    font-size: 18px;
  }
  .adminLoginForm {
    width: 90%;
  }
  .adminLoginHeader h1 {
    font-size: 10vw !important;
    text-align: center;
  }
  input[type="file"] {
    width: 75%;
    margin: auto;
  }
  .uploadForms .column2 {
    margin-top: 20% !important;
  }
  .userStepOnePage {
    width: 100% !important;
    height: 93.1vh !important;
    overflow: scroll;
    margin: 0%;
  }
  .userStepOnePage #buttons {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0%;
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }
  .userStepOnePage #buttons button {
    display: block;
    text-align: center;
  }
  #wordContainer #word, #nineXContainer #nineXWord {
    font-size: 15vw;
  }
  #flashAlphabet #flash, #flashAlphabet2 #flash2 {
    font-size: 15vw;
  }
  #nextStep .nextStepButton {
    float: none;
    margin: auto;
    /* margin: 0% !important; */
    /* margin: auto !important; */
  }
  #nextStep {
    padding-top: 20vh !important;
  }

  .userStepTwoPage {
    width: 100% !important;
  }

  .userStepThreePage {
    width: 100% !important;
    height: 93.1vh !important;
    margin: 0%;
  }
  .userStepThreePage .tableWrapper3 {
    padding-top: 20vh;
  }
  .table2x4 {
    height: 30% !important;
    width: 100% !important;
    margin-top: 0% !important;
  }
  .table2x4 td {
    width: 13vw !important;
    height: 12vw !important;
    padding-top: 0% !important;
  }
  .userStepThreePage #flash_result img {
    width: 100% !important;
  }
  .userGame2Page {
    scroll-margin-top: 10%;
    overflow: scroll;

    width: 100% !important;
    height: 93.1vh !important;
    margin: 0%;
  }

  .userGame2Page #_flash_image {
    width: 30vw;
  }

  .userGame2Page #_mobile_buttons {
    display: block;
  }
  .userGame2Page #_btn_start {
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #_flash_word{
    margin-top: 15% !important;
  }
  .keyboard-instructions {
    display: none;
  }
  .touchscreen-instructions {
    display: block;
  }

  .adminLoginHeader h1 {
    font-size: 5vw !important;
    text-align: center;
  }
  .uploadForms .column2 {
    margin-top: 20% !important;
  }
  .userStepOnePage {
    width: 70vw !important;
  }

  .userStepThreePage {
    width: 80% !important;
    /* height: !important; */
    margin: auto;
    margin-top: 10vh;
  }
  .table2x4 {
    height: 30vh !important;
  }

  .userStepThreePage #flash_result img {
    width: 20vw !important;
  }
  .userGame2Page #_mobile_buttons {
    display: block;
  }
  .userGame2Page #_btn_start {
    display: block;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 765px) {
  .userStepThreePage .flashWord {
    /* background-color: gold; */
    height: 90px !important;
  }
  .userStepThreePage .flashWord .wordInside h4 {
    /* width: 10vw; */
    min-width: 10vw;
    padding-top: 36%;
    height: 100%;
    font-size: 16px;
  }
  .userStepThreePage .table2x4 h4 {
    /* padding-top: 42%;
    padding-bottom: 15%;
    font-size: 1.5vw; */
    padding-top: 0%;
    padding-bottom: 0%;
    font-size: 3.5vw;
  }

  .keyboard-instructions {
    display: none;
  }
  .touchscreen-instructions {
    display: block;
  }

  .adminLoginHeader h1 {
    font-size: 5vw !important;
  }
  .adminLoginForm {
    width: 50% !important;
  }
  .uploadForms .column2 {
    margin-top: 0px !important;
  }
  .userStepOnePage {
    width: 70vw !important;
  }
  .userStepThreePage #flash_result img {
    width: 100% !important;
  }
  .userStepThreePage {
    width: 80% !important;
    /* height: 100% !important; */
    margin: auto;
    margin-top: 10vh;
  }
  .table2x4 {
    height: 30vh !important;
  }

  .userGame2Page #_mobile_buttons {
    display: block;
  }
  .userGame2Page #_btn_start {
    display: block;
  }
  .userGame2Page {
    width: 80vw !important;
  }
  .userGame2Page #_flash_image {
    width: 12vw;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .keyboard-instructions {
    display: none;
  }
  .touchscreen-instructions {
    display: block;
  }
  .adminLoginForm {
    width: 35% !important;
  }
  .adminLoginHeader h1 {
    font-size: 5vw !important;
  }
  .userStepOnePage {
    width: 70vw !important;
    margin-top: 10vh !important;
  }

  .userStepThreePage {
    width: 80% !important;
    height: 78vh !important;
    margin: auto;
    margin-top: 10vh;
  }
  .table2x4 {
    height: 30vh !important;
  }
  .userGame2Page #_mobile_buttons {
    display: block;
  }
  .userGame2Page #_btn_start {
    display: block;
  }

  .userGame2Page {
    width: 80vw !important;
  }
  .userGame2Page #_flash_image {
    width: 12vw;
  }
}
@media only screen and (min-width: 1030px) {
  .userStepThreePage .flashWord {
    /* background-color: gold; */
    height: 90px !important;
  }
  .keyboard-instructions {
    display: none;
  }
  .touchscreen-instructions {
    display: block;
  }
  .adminLoginForm {
    width: 35% !important;
  }
  .adminLoginHeader h1 {
    font-size: 3vw !important;
    text-align: left;
  }
  .userStepOnePage {
    box-shadow: 2px 2px 12px gray;
    width: 60vw !important;
    margin-top: 10vh;
    background-color: rgb(248, 248, 236) !important;
  }
  .userStepThreePage {
    width: 80% !important;
    /* height: !important; */
    margin: auto;
    margin-top: 10vh;
  }
  .table2x4 {
    height: 30vh !important;
  }
  .userStepThreePage #flash_result img {
    width: 10vw !important;
  }

  .userGame2Page #_mobile_buttons {
    display: block;
  }
  .userGame2Page #_btn_start {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1080px) {
  .adminLoginForm {
    width: 35% !important;
  }
  .adminLoginHeader h1 {
    font-size: 3vw !important;
    text-align: left;
  }
  .userStepOnePage {
    box-shadow: 2px 2px 12px gray;
    width: 50vw !important;
    margin-top: 10vh;
    background-color: rgb(248, 248, 236) !important;
  }

  .userStepThreePage {
    box-shadow: 2px 2px 12px gray;
    width: 50vw !important;
  }

  .userStepThreePage .table2x4 {
    margin: auto;
  }
  .userStepThreePage .table2x4 td {
    height: 50% !important;
    width: 20% !important;
    cursor: context-menu;
  }

  .userStepThreePage #flash_result img {
    width: 8vw !important;
  }
  .userStepThreePage .flashWord .wordInside h4 {
    border: 1px solid black;
    min-width: 8vw;
    padding-top: 50%;
    height: 10vw;
  }
  .userGame2Page {
    width: 50vw !important;
    height: 75vh !important;
  }
  .userGame2Page #_mobile_buttons {
    display: none;
  }
  .userGame2Page #_btn_start {
    display: none;
  }
  .userGame2Page #_flash_image {
    width: 10vw;
  }

}

@media only screen and (min-width: 1200px) {

  .userStepThreePage .flashWord {
    /* background-color: gold; */
    /* display: inline-flex; */
    height: 100% !important;
  }
}

@media only screen and (min-width: 1030px) {
  .userStepThreePage .table2x4 h4 {
    padding-top: 15%;
    padding-bottom: 0%;
    font-size: 2vw;
  }
  .keyboard-instructions {
    display: block;
  }
  .touchscreen-instructions {
    display: none;
  }
  #hint_left,
  #hint_right {
    display: block;
  }
}
@media only screen and (max-width: 475px) {
  .userStepTwoPage .tableWrapper .quadrantTable img {
    max-width: 140px;
  }
}

@media only screen and (max-width: 375px) {
  .userStepTwoPage .tableWrapper .quadrantTable img {
    max-width: 110px;
  }
}

/* @media screen and (min-width: 1920px) and (max-width: 1980px)
{
.loading-style
{
 width: 8vw;
 height: 10vw;
}
} */
